import React, { useState, useRef, useEffect } from 'react';
import emailjs from '@emailjs/browser';
import './index.scss';

export const ContactUs = () => {
  const form = useRef();
  const [message, setMessage] = useState({ text: "", type: "" });

  // Effacer le message après 3 secondes
  useEffect(() => {
    if (message.text !== "") {
      const timer = setTimeout(() => setMessage({ text: "", type: "" }), 3000);
      return () => clearTimeout(timer);
    }
  }, [message]);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_eq50qg7', 'template_8fjsb0k', form.current, 'H8AJiL6BSqnXd-QqP')
    .then((result) => {
      setMessage({ text: "Votre message a été envoyé avec succès.", type: "success" });
      form.current.reset();
    }, (error) => {
      setMessage({ text: "Erreur lors de l'envoi du message.", type: "error" });
    });
  };

  return (
    <>
     
      <form className='contact' ref={form} onSubmit={sendEmail}>
        <h2 className='title'>Contact</h2>
        <label>Nom</label>
        <input type="text" name="user_name" required/>
        <label>Email</label>
        <input type="email" name="user_email" required/>
        <label>Message</label>
        <textarea name="message" required />
        <button type="submit" value="Send">Envoyer</button>
         {/* Modifier ici pour utiliser le texte et la classe basée sur le type */}
         {message.text && (
        <div className={`feedback-message ${message.type}`}>
          {message.text}
        </div>
      )}
      </form>
     
      {/* Gérer également les messages d'erreur si nécessaire */}
    </>
  );
};

export default ContactUs;
