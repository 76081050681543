
import './index.scss'

const Info = () => {
    return (
        <div className="info">

            <div className="container">
                <div className="title">
                    <h2>À propos de moi</h2>
                    <div className="info-description">
                        <p>
                            Bienvenue sur mon portfolio. À 26 ans, je suis un développeur web engagé, ayant complété une formation diplômante Bac +2.  <br />
                            <br />
                            Ma pratique personnelle m'a permis de maîtriser les langages comme : HTML, CSS, Scss, JavaScript, React, Node.js, phpMyAdmin, MySQL, Liquid et d'autres encore...
                            <br /> <br />

                            Je me spécialise dans la création d'applications web front-end, tout en élargissant mes compétences vers les technologies back-end.
                        </p>
                    </div>
                </div>
            </div>
        </div>)
};

export default Info;